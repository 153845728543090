var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState, useEffect } from "react";
var ReactPasswordChecklist = function (_a) {
    var className = _a.className, style = _a.style, rules = _a.rules, value = _a.value, valueAgain = _a.valueAgain, minLength = _a.minLength, maxLength = _a.maxLength, rtl = _a.rtl, onChange = _a.onChange, _b = _a.specialCharsRegex, specialCharsRegex = _b === void 0 ? /[~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_]/g : _b, _c = _a.messages, messages = _c === void 0 ? {} : _c, remainingProps = __rest(_a, ["className", "style", "rules", "value", "valueAgain", "minLength", "maxLength", "rtl", "onChange", "specialCharsRegex", "messages"]);
    var _d = useState(false), isValid = _d[0], setIsValid = _d[1];
    var ruleDefinitions = {
        minLength: {
            valid: value.length >= (minLength || 100),
            message: messages.minLength || "Password has at least ".concat(minLength, " characters."),
        },
        specialChar: {
            valid: specialCharsRegex.test(value),
            message: messages.specialChar || "Password has special characters.",
        },
        number: {
            valid: /\d/g.test(value),
            message: messages.number || "Password has a number.",
        },
        capital: {
            valid: (function () {
                var i = 0;
                if (value.length === 0) {
                    return false;
                }
                while (i < value.length) {
                    var character = value.charAt(i);
                    if (character == character.toLowerCase()) {
                        // Character is lowercase, numeric, or a symbol
                    }
                    else if (character == character.toUpperCase()) {
                        return true;
                    }
                    i++;
                }
                return false;
            })(),
            message: messages.capital || "Password has a capital letter.",
        },
        match: {
            valid: value.length > 0 && value === valueAgain,
            message: messages.match || "Passwords match.",
        },
        lowercase: {
            valid: (function () {
                var i = 0;
                if (value.length === 0) {
                    return false;
                }
                while (i < value.length) {
                    var character = value.charAt(i);
                    if (character == character.toUpperCase()) {
                        // Character is lowercase, numeric, or a symbol
                    }
                    else if (character == character.toLowerCase()) {
                        return true;
                    }
                    i++;
                }
                return false;
            })(),
            message: messages.lowercase || "Password has a lowercase letter.",
        },
        letter: {
            valid: /[a-zA-Z]/g.test(value),
            message: messages.letter || "Password has a letter.",
        },
        maxLength: {
            valid: value.length <= (maxLength || 16),
            message: messages.maxLength || "Password has no more than ".concat(maxLength, " characters."),
        },
        notEmpty: {
            valid: Boolean(value.length > 0 && valueAgain && valueAgain.length > 0),
            message: messages.notEmpty || "Password fields are not empty.",
        },
    };
    var enabledRules = rules.filter(function (rule) { return Boolean(ruleDefinitions[rule]); });
    useEffect(function () {
        if (enabledRules.every(function (rule) { return ruleDefinitions[rule].valid; })) {
            setIsValid(true);
        }
        else {
            setIsValid(false);
        }
    }, [value, valueAgain]);
    useEffect(function () {
        if (typeof onChange === "function") {
            onChange(isValid, enabledRules.filter(function (rule) { return !ruleDefinitions[rule].valid; }));
        }
    }, [isValid]);
    if (rtl) {
        className = className ? className + " rtl" : "rtl";
    }
    return (React.createElement("ul", { className: className, style: __assign({ margin: 0, padding: 0 }, style) }, enabledRules.map(function (rule) {
        var _a = ruleDefinitions[rule], message = _a.message, valid = _a.valid;
        return (React.createElement(Rule, __assign({ key: rule, valid: valid, iconSize: 18, validColor: "#4BCA81", invalidColor: "#FF0033", rtl: rtl }, remainingProps), message));
    })));
};
var Rule = function (_a) {
    var valid = _a.valid, iconSize = _a.iconSize, validColor = _a.validColor, invalidColor = _a.invalidColor, validTextColor = _a.validTextColor, invalidTextColor = _a.invalidTextColor, iconComponents = _a.iconComponents, hideIcon = _a.hideIcon, rtl = _a.rtl, children = _a.children;
    return (React.createElement("li", { className: valid ? "valid" : "invalid", style: {
            listStyleType: "none",
            display: "flex",
            alignItems: "flex-start",
            margin: "2px 0",
        } },
        !hideIcon ? (iconComponents ? (valid ? (iconComponents.ValidIcon) : (iconComponents.InvalidIcon)) : (React.createElement("svg", { className: "checklist-icon", version: "1.1", xmlns: "http://www.w3.org/2000/svg", width: iconSize, height: iconSize, viewBox: "0 0 512 512", style: { marginRight: rtl ? 0 : 5, marginLeft: rtl ? 5 : 0 } },
            React.createElement("path", { fill: valid ? validColor : invalidColor, d: valid
                    ? "M432 64l-240 240-112-112-80 80 192 192 320-320z"
                    : "M507.331 411.33c-0.002-0.002-0.004-0.004-0.006-0.005l-155.322-155.325 155.322-155.325c0.002-0.002 0.004-0.003 0.006-0.005 1.672-1.673 2.881-3.627 3.656-5.708 2.123-5.688 0.912-12.341-3.662-16.915l-73.373-73.373c-4.574-4.573-11.225-5.783-16.914-3.66-2.080 0.775-4.035 1.984-5.709 3.655 0 0.002-0.002 0.003-0.004 0.005l-155.324 155.326-155.324-155.325c-0.002-0.002-0.003-0.003-0.005-0.005-1.673-1.671-3.627-2.88-5.707-3.655-5.69-2.124-12.341-0.913-16.915 3.66l-73.374 73.374c-4.574 4.574-5.784 11.226-3.661 16.914 0.776 2.080 1.985 4.036 3.656 5.708 0.002 0.001 0.003 0.003 0.005 0.005l155.325 155.324-155.325 155.326c-0.001 0.002-0.003 0.003-0.004 0.005-1.671 1.673-2.88 3.627-3.657 5.707-2.124 5.688-0.913 12.341 3.661 16.915l73.374 73.373c4.575 4.574 11.226 5.784 16.915 3.661 2.080-0.776 4.035-1.985 5.708-3.656 0.001-0.002 0.003-0.003 0.005-0.005l155.324-155.325 155.324 155.325c0.002 0.001 0.004 0.003 0.006 0.004 1.674 1.672 3.627 2.881 5.707 3.657 5.689 2.123 12.342 0.913 16.914-3.661l73.373-73.374c4.574-4.574 5.785-11.227 3.662-16.915-0.776-2.080-1.985-4.034-3.657-5.707z" })))) : null,
        React.createElement("span", { style: {
                paddingTop: 2,
                opacity: valid ? 1 : !invalidTextColor ? 0.5 : undefined,
                flex: 1,
                color: valid ? validTextColor : invalidTextColor,
            } }, children)));
};
export default ReactPasswordChecklist;
